import actionHelper from '../redux/actionHelper'
import * as actionTypes from '../redux/actionTypes/actionTypes'
import LoadingOverlay from '../components/loadingOverlay/loading_overlay'
import Toast from '../components/Toast/toast'
import Modal from '../components/Modal/modalWithInputs'
import AUX from '../hoc/auxiliary'
import { setLocalStorageOrCookie, getLocalStorageOrCookie } from '../helpers/localStorageAndCookieHelper'

const examplesComponent = (props:any) => {
  const callback = (simpleModal:boolean) => {
    if(!simpleModal){
      if(props.modal.inputValues[0] === undefined || props.modal.inputValues[0].length <3 || props.modal.inputValues[1] === undefined || props.modal.inputValues[1].length <3){
        return({success:false,toastText:"Login failed, please try again", counter:1000})
      }
    else{
      return({success:true})}
  }
  else{return({success:true})}
    }
    

    return(
    <AUX>
      <button onClick={() => actionHelper(actionTypes.UPDATESTATE, {loading:true})}>Toggle Loading screen</button>
      <button onClick={() => actionHelper(
        actionTypes.UPDATESTATE, 
        {modal: {...props.modal,
          show:true, 
          showInputFields:false, 
          inputValues: [],
          inputFieldTypes:[
            {type: "text", placeholder:"Enter username here", text:"Username: "},
            {type: "password", placeholder: "Enter password here", text:"Password: "}
            ], text:"Please enter your information below.", confirmText: "Send (ENTER)", cancelText: "Cancel (ESC)"}})}>
              Show Modal
      </button>
      <button onClick={() => actionHelper(actionTypes.UPDATESTATE, {toast:{show:true, text:"I am a toast", counter:1000}})}>Show Toast</button>
      <button onClick={() =>setLocalStorageOrCookie("isAuth", false)}>Set setLocalStorageOrCookie</button>
      <button onClick={() =>console.log(getLocalStorageOrCookie("userData2"))}>Get setLocalStorageOrCookie</button>
      <br />To do: <br />
      <ol>
      <li>Style theme handler.</li>
      </ol>
      <Modal modalSettings={props.modal} cb={callback}></Modal>
      <Toast toastSettings={props.toast}></Toast>
      <LoadingOverlay loading={props.loading}/>
    </AUX>
    )
    
}

export default examplesComponent