const urlQueryParamsCutter = (returnParams:boolean, returnQueryParams:boolean) => {
    //Cut out URL substring after last "/"
    try{
        const pageURL = window.location.href.split("/")
    const urlParamsAndQueryParams:any = pageURL[pageURL.length-1].split("?")
    let returnObject:any = {}
    if(returnParams){ 
        returnObject.param = urlParamsAndQueryParams[0]
    }

    if(returnQueryParams){
        const queryParams = (returnParams) ? urlParamsAndQueryParams[1]: urlParamsAndQueryParams[0]
        let queryParamsToMap = queryParams.split("&")
        queryParamsToMap.map((queryParam:any) => {
            const queryParamKeyAndValue = queryParam.split("=")
            returnObject[queryParamKeyAndValue[0]] = queryParamKeyAndValue[1]
        })
    }
    return returnObject
    }
    catch(err){
        return false
    }
    

    
}
//http://localhost:3000/products/1234?productbuyable=true&color=red
export default urlQueryParamsCutter