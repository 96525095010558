import CSSTransition from 'react-transition-group/CSSTransition'
import modalTypes from './modalTypes'
import keyStrokeHandler from '../../helpers/keyStrokeHandler'
import actionHelper from '../../redux/actionHelper'
import * as actionTypes from '../../redux/actionTypes/actionTypes'
import AUX from '../../hoc/auxiliary'
import './modal.scss'
import { useRef } from 'react'
import {Button} from 'reactstrap'

interface PropsType {
    modalSettings: modalTypes
    cb(...args: any[]):void
}

const animationTimes = {
    enter: 400,
    exit: 500
  }

const ModalHandler = (props:PropsType) => {
  
  let inputValues:Array<any> = [...props.modalSettings.inputValues] || []

  const keyListener = (e:Event) => {
    keyStrokeHandler(e, "ESCAPE", () =>{cancelButtonRef.current?.click()})
    keyStrokeHandler(e, "ENTER", () => {confirmButtonRef.current?.click()}
    )}

  const backDropClick = () => {
    window.removeEventListener("keyup", keyListener,true)
    actionHelper(actionTypes.UPDATESTATE, {modal: {...props.modalSettings,show:false, showInputField:false}})
  }


  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const confirm = () => {
    console.log(props.modalSettings.inputValues)
    if(props.modalSettings.showInputFields === true){
      let askCallback:any = props.cb(false)
      if(askCallback.success === false){ 
        return}
    }
    actionHelper(actionTypes.UPDATESTATE,{modal:{...props.modalSettings, confirmButtonPressed:true}})
    backDropClick()
  }

    return(<AUX>
      <CSSTransition 
          mountOnEnter={true}
          unmountOnExit={true}
          in={props.modalSettings.show} 
          onEntered={() => {
              window.addEventListener("keyup", keyListener,true)
            }
          }
          timeout={animationTimes}
          classNames="Backdrop-Animation">
          <div className="Backdrop" onClick={() => backDropClick()}>
          </div>
      </CSSTransition>
        <CSSTransition 
          mountOnEnter={true}
          unmountOnExit={true}
          in={props.modalSettings.show} 
          timeout={animationTimes}
          classNames="Modal-Animation">
          <div className="Modal">
            {props.modalSettings.text}<br />
            {(props.modalSettings.showInputFields)?
            props.modalSettings.inputFieldTypes.map((field, index) => {
              return <AUX key={index}>
                {field.text}<input type={field.type} onChange={
                  (e) => {
                    inputValues[index] = e.target.value
                    actionHelper(actionTypes.UPDATESTATE,{modal: {...props.modalSettings,inputValues: inputValues}})
                }} placeholder={field.placeholder}></input><br />
                </AUX>
            })
            :
            null}
            <Button id="modalAcceptButton" onClick={() => confirm()} innerRef={confirmButtonRef}>{props.modalSettings.confirmText}</Button>
            <Button id="modalCancelButton" onClick={() => backDropClick()} innerRef={cancelButtonRef}>{props.modalSettings.cancelText}</Button>
          </div>
      </CSSTransition>
      </AUX>
    )

   
}

export default ModalHandler