import React, {Component, Suspense} from 'react';
import './App.css'
import {Amplify} from 'aws-amplify'
import awsConfig from './aws-exports'
import {connect} from "react-redux"
import ScrollToTop from 'react-scroll-up'
import modalType from './components/Modal/modalTypes'
import toastType from './components/Toast/toastType'
import EXAMPLES from './containers/examples'
import LoadingOverlay from './components/loadingOverlay/loading_overlay'
import { getLocalStorageOrCookie } from './helpers/localStorageAndCookieHelper'
import actionHelper from './redux/actionHelper'
import * as actionTypes from './redux/actionTypes/actionTypes'
import globalStyle from './helpers/styleThemeHandler'
import Toast from './components/Toast/toast'
import WarningOverlay from './components/visualWarningOverlay/visualWarningOverlay'
import checkIfJWTExpiredandLogout from './helpers/checkExpirationAndLogOut'
import paramsCutter from './helpers/queryParamsCutter'
const IsAuth = React.lazy(() => import('./containers/authenticated/authenticated'))
const UnAuth = React.lazy(() => import('./containers/unauthenticated'))

Amplify.configure(awsConfig)

interface reduxProps {
  showExamples:boolean
  isAuth:boolean
  toast:toastType
  modal:modalType
  loading: boolean
  styleTheme:string
  loadingText:string
  warningOverlayShow:boolean
}

const stateToProps = function(state:any){
  return {
    loading:state.loading, 
    isAuth:state.isAuth, 
    toast:state.toast, 
    modal:state.modal, 
    showExamples:state.showExamples, 
    styleTheme:state.styleTheme, 
    loadingText:state.loadingText,
    warningOverlayShow:state.warningOverlayShow
}}

class App extends Component<reduxProps> {

  async componentDidMount(){
    //(isAuth) ? actionHelper(actionTypes.UPDATESTATE, {isAuth: true,loading:false}): actionHelper(actionTypes.UPDATESTATE, {isAuth: false,loading:false})
    //document.body.style.backgroundColor = "black";
    //document.body.style.color = "white";
    //actionHelper(actionTypes.UPDATESTATE, {toast: {show:false, text:"<div>Test</div>"}})
    //dataPoster("sheetSearch", `tags:"Igor,Spec,Kalmar,Dental" skip:0 limit:15`,"data", this.props.JWT)
    //sheetSearch(tags:"Igor,Spec,Kalmar,Dental" skip:0 limit:15){data}
    globalStyle(this.props.styleTheme)
    const expirationTime = getLocalStorageOrCookie("autoLog")
    const cookiesAccepted = getLocalStorageOrCookie("cookiesAccepted")
    if(cookiesAccepted !== null){
      actionHelper(actionTypes.UPDATESTATE, {cookiesAccepted:true})
    }
    if(expirationTime!==false){checkIfJWTExpiredandLogout(+expirationTime)}
    const authData:any = getLocalStorageOrCookie("authData")
    if(authData !== null){
      actionHelper(actionTypes.UPDATESTATE, authData)
    }
    const pageLanguage:any = getLocalStorageOrCookie("pageLanguage")
    if(pageLanguage!==null){
      actionHelper(actionTypes.UPDATESTATE, {pageLanguage:pageLanguage, generateLanguage:pageLanguage})
    }
    
    let params:any = paramsCutter(true, true)
    if(params.clientEmail){
      actionHelper(actionTypes.UPDATESTATE, {clientEmail:params.clientEmail})
    }
  }

  render(){
    return (
    <div className="App">
        {(this.props.isAuth) ? 
          <Suspense fallback={<LoadingOverlay loading={this.props.loading}></LoadingOverlay>}>
            <IsAuth></IsAuth>
          </Suspense>
        :
          <Suspense fallback={<LoadingOverlay loading={this.props.loading}></LoadingOverlay>}>
             <UnAuth></UnAuth>
          </Suspense>
        }
      {(this.props.showExamples === true) ? <EXAMPLES modal={this.props.modal} toast={this.props.toast} loading={this.props.loading}></EXAMPLES>: null}
      <Toast toastSettings={this.props.toast}></Toast>
      <LoadingOverlay loading={this.props.loading} text={this.props.loadingText}/>
      <WarningOverlay show={this.props.warningOverlayShow}/>
      <ScrollToTop showUnder={160} style={{bottom:"0.2rem",right:"235px",zIndex:"15"}}>
      <i className="fas fa-chevron-circle-up toTopButton"></i>
      </ScrollToTop>
    </div>
  )}
  
}

export default connect(stateToProps)(App);