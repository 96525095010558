export const setLocalStorageOrCookie = (key:string, value:any) => {
        if(typeof(Storage) !== undefined){
            localStorage.setItem(encodeURIComponent(key),encodeURIComponent(JSON.stringify(value)))
            console.log(`Key ${key} has been saved to the local storage with value ${JSON.stringify(value)}`)
        }
        else if(navigator.cookieEnabled){
            document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value))
            console.log(`Key ${key} has been saved to a cookie with value ${JSON.stringify(value)}`)
        }
        else return false
}

export const getLocalStorageOrCookie = (key:string) => {
    if(typeof(Storage) !== undefined){
        let data:any = localStorage.getItem(encodeURIComponent(key))
        return JSON.parse(decodeURIComponent(data))
    }
    else if(navigator.cookieEnabled){
        let data:any = document.cookie.split(";")
        let filteredData:string = ""
        data.map((item:string) => {
            item = item.replace(" ","")
            if(item.startsWith(key+"=")){
                item = item.replace(key+"=","")
                filteredData = JSON.parse(decodeURIComponent(item))
            }
        }) 
        return filteredData
    }
    else return false
}

export const removeLocalStorageOrCookie = (key:string) => {
    if(typeof(Storage) !== undefined){
        localStorage.removeItem(key)
    }
    else if(navigator.cookieEnabled){
        document.cookie = encodeURIComponent(key) + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
    }
    else return false
}