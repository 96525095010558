const styleThemeHandler = (styleName:string) => {
    switch (styleName) {
        case "light":
            // @ts-ignore
            return import('../assets/styleThemes/light.css')

        case "dark":
            // @ts-ignore
            return import('../assets/styleThemes/dark.css')
    
        default:
            break;
    }
}

export default styleThemeHandler