export default function reducer(state:any, action:any) {

    switch (action.type) {
        case "UPDATESTATE":
            return {...state, ...action.payload}
    
        default:
            return {...state}
    }

    }