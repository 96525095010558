import sanitizeHTML from 'sanitize-html'

//Verify that String contains "non-digit whitespace non-digit" pattern
export const fullNameValidate = (name:string) => {
    let nameArray = name.split(" ")
    let validated = (nameArray.length>1 && nameArray[0].length!==0)? true:false
    return validated
}

//Check if inputs match
export const equalValidate = (input1:String, input2:String) => {
    let validated = (input1 === input2)?true:false
    return validated
}

//Check if length is equal to or greater then
export const lengthValidate = (input:string, length:number) => {
    let validated = (input.length>=length)?true:false
    return validated
}

//Check if email follows "chars@chars.chars" pattern
export const emailValidate = (email:string) => {
    let regEx = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
    let validated = (regEx.test(email))?true:false
    return validated
}

//Check if timestamp within expirationtime
export const expirationCheck = (expirationTime:number) => {
    let validate = (expirationTime>=Date.now()) ? true: false
    return validate
}

//Simple input string sanitizer that disallows all special characters
export const simpleStringSanitizer = (input:string) => {
    let regEx = /[-'`~!@#$%^&*()½¾¼¤_|+=?'"<>\{\}\[\]\\\/]/gi
    let sanitized = input.replace(regEx, "")
    return sanitized
}

//Complex input string sanitizer which sanitizes HTML strings
export const htmlStringSanitizer = (input:string) => {
    let sanitized = sanitizeHTML(input, 
        {
            allowedTags: [ 'br', 'strong', 'em', 'span', 'sub', 'sup', 'img', 'ul', 'ol', 'li', 'div', 'a'],
            allowedAttributes: {
                'a': [ 'href', 'target', 'title'],
                'img': [ 'src', 'width', 'height', 'alt' ],
                'span': [ 'style' ],
                'div': ['style']
            },
            allowedStyles: {
                '*': {
                  'color': [/^#(0x)?[0-9a-f]+$/i],
                  'text-align': [/^left$/, /^right$/, /^center$/],
                  'text-decoration': [/^underline$/,/^line-through$/],
                  //'font-family': [/^([, ]*[\w]*)*/i],
                  'font-size': [/^\d+(?:px|em|pt|%)$/]
                }
            }
      }
    )
    return sanitized
}