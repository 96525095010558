import CSSTransition from "react-transition-group/CSSTransition";
import actionHelper from "../../redux/actionHelper";
import * as actionTypes from '../../redux/actionTypes/actionTypes'
import "./visualWarningOverlay.scss"

const animationTimes = {
    enter: 100,
    exit: 100
  }

const WarningOverlay = (props:propsTypes) => {
    return(
    <CSSTransition 
          mountOnEnter={true}
          unmountOnExit={true}
          in={props.show} 
          timeout={animationTimes}
          onEntered={() => actionHelper(actionTypes.UPDATESTATE, {warningOverlayShow:false})}
          classNames="warningOverlay">
              <div className="warningOverlay"></div>
      </CSSTransition>
      )
}

export default WarningOverlay

interface propsTypes {
    show:boolean
}