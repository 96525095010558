import CSSTransition from "react-transition-group/CSSTransition";
import "./toast.scss";
import toastType from '../../components/Toast/toastType'
import actionHelper from "../../redux/actionHelper";
import * as actionTypes from '../../redux/actionTypes/actionTypes'

interface PropsType {
    toastSettings: toastType
}

const animationTimes = {
  enter: 400,
  exit: 500
}


const toast = (props:PropsType) => {
  let initialCounterValue:number
  let internalCounter:number
  const toastingTime = () => {
    let initialCounterValue = props.toastSettings.counter
    let internalCounter = props.toastSettings.counter
    let ticker = setInterval(() => {
      internalCounter -= 1000
      actionHelper(actionTypes.UPDATESTATE, {toast: {...props.toastSettings, counter: internalCounter}})
      if(internalCounter < 1){
        killTicker()
        props.toastSettings.show = false;
        actionHelper(actionTypes.UPDATESTATE, {toast: {...props.toastSettings, show: !props.toastSettings.show}})
        setTimeout(() => {
          actionHelper(actionTypes.UPDATESTATE, {toast: {...props.toastSettings, counter: initialCounterValue}})
        },initialCounterValue/2)
      }
    } 
    ,1000)
  
    const killTicker = () => clearInterval(ticker)
  }
    return(
        <CSSTransition 
          mountOnEnter={true}
          unmountOnExit={true}
          in={props.toastSettings.show} 
          timeout={animationTimes}
          onEntered={() => toastingTime()}
          classNames="Toast-Animation">
          <div className="Toast" >
            {props.toastSettings.text}
          </div>
      </CSSTransition>
    )
}

export default toast