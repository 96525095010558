import {expirationCheck} from '../helpers/validators-sanitizers'
import {removeLocalStorageOrCookie} from '../helpers/localStorageAndCookieHelper'

const checkJWTExpirationTimeAndLogout = (time:number) => {
    const expired:boolean = !expirationCheck(time)

    if(expired){
        removeLocalStorageOrCookie("authData")
        removeLocalStorageOrCookie("autoLog")
    }
}

export default checkJWTExpirationTimeAndLogout