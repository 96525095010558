const keyStrokeHandler = (event:any, key:string, func:any) => {
  const pressedKey = () => {
    switch(key){
    case 'ENTER': return "Enter"
    case 'ESCAPE': return "Escape"
    case 'BACKSPACE':return "Backspace"
    case 'ARROWUP': return "ArrowUp"
    case 'ARROWDOWN': return "ArrowDown"
  }}

  if(event.key === pressedKey()){
    func()
  }
}

export default keyStrokeHandler