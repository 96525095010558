import './loading.scss';

const loadingOverlay = (props:any) => {
    return (props.loading && props.text)?
          <div id="loadingWrapper">
          <div id="loading">
          <div className="loadingio-spinner-dual-ball-tlktrepq2"><div className="ldio-34f2r4bk1ps">
<div></div><div></div><div></div>
</div></div>
            {/* <img id="loadingrotation" alt="Load" src={mySvg}></img> */}
            <div className="loadingText">
              {Array.from(props.text).map((letter:any, index:any) => {
                return(<span key={index}>{letter}</span>)
              })}
            </div>
          </div>
          </div>
        : null
      
      }

export default loadingOverlay